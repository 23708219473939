// cra imports
import { lazy, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
// import html head tags requirements
import { Helmet } from 'react-helmet';
// import redux requirements
import { Provider } from 'react-redux';
// import routing modules
import { BrowserRouter } from 'react-router-dom';
// import toastify for notification
import { Slide, ToastContainer } from 'react-toastify';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import Loading from './components/loading/Loading';
import { DEFAULT_PATHS, REACT_HELMET_PROPS } from './config';
import { RouteModelV2 } from './models/route/route.model';
// import reportWebVitals from './reportWebVitals';
import routesAndMenuItems from './routes';
import RouteIdentifier from './routing/components/RouteIdentifier';
// import routes
import { getLayoutlessRoutes } from './routing/helper';
import { persistedStore, store } from './store';

const NotFound = lazy(() => import('./views/default/NotFound'));
const Login = lazy(() => import('./views/default/Login'));
const ForgotPassword = lazy(() => import('./views/default/ForgotPassword'));
const Register = lazy(() => import('./views/default/Register'));
const ResetPassword = lazy(() => import('./views/default/ResetPassword'));
const Unauthorized = lazy(() => import('./views/default/Unauthorized'));
const InvalidAccess = lazy(() => import('./views/default/InvalidAccess'));
const ConfirmResetPassword = lazy(() => import('./views/default/ConfirmResetPassword'));
const ConfirmRegistration = lazy(() => import('./views/default/ConfirmRegistrationCode'));
const App = lazy(() => import('./App'));

const defaultRoutes: RouteModelV2[] = [
  { path: DEFAULT_PATHS.NOTFOUND, element: NotFound },
  { path: DEFAULT_PATHS.LOGIN, element: Login },
  { path: DEFAULT_PATHS.REGISTER, element: Register },
  { path: DEFAULT_PATHS.FORGOT_PASSWORD, element: ForgotPassword },
  { path: DEFAULT_PATHS.CONFIRM_RESET_PASSWORD, element: ConfirmResetPassword },
  { path: DEFAULT_PATHS.CONFIRM_REGISTRATION, element: ConfirmRegistration },
  { path: DEFAULT_PATHS.RESET_PASSWORD, element: ResetPassword },
  { path: DEFAULT_PATHS.UNAUTHORIZED, element: Unauthorized },
  { path: DEFAULT_PATHS.INVALID_ACCESS, element: InvalidAccess },
  { path: DEFAULT_PATHS.APP, element: App },
];

const Main = () => {
  const layoutlessRoutes = useMemo(() => getLayoutlessRoutes({ data: routesAndMenuItems }), []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <Helmet {...REACT_HELMET_PROPS} />
        <ToastContainer transition={Slide} newestOnTop />
        <BrowserRouter>
          <RouteIdentifier routes={[...layoutlessRoutes, ...defaultRoutes]} fallback={<Loading />} notFoundPath={undefined} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

const container: any = document.getElementById('root');
const root: any = createRoot(container);
root.render(<Main />);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// reportWebVitals(console.log);

//        <Router // basename={process.env.REACT_APP_BASENAME}        >
