/* eslint-disable */
import { lazy } from 'react';
import { PermissionsENUM } from 'ta-shared-dto-node/dist/enums/auth/permissions.enum';
import { DEFAULT_PATHS } from './config';
import { RouteAndMenuItemModel } from './models/route/routeAndMenuItem.model';

const dashboard = lazy(() => import('./views/dashboard/Dashboard'));

const webclock = {
  home: lazy(() => import('./views/clock/MobileClock.screen')),
  details: lazy(() => import('./views/clock/details/PunchDetails.screen')),
};

const employees = {
  list: lazy(() => import('./views/employees/list/EmployeeList')),
  detail: lazy(() => import('./views/employees/detail/EmployeeDetails')),
};

const timecard = {
  home: lazy(() => import('./views/timecard/Timecard')),
};

const schedule = {
  home: lazy(() => import('./views/schedule/Schedule')),
};

const reporting = {
  home: lazy(() => import('./views/reporting/Home')),
};

const receipes = {
  home: lazy(() => import('./views/receipe/ReceipeDetails')),
  detail: lazy(() => import('./views/receipe/ReceipeDetails')),
};

const dataAndIntegration = {
  appAndIntegration: lazy(() => import('./views/dataAndintegration/AppsAndIntegration')),
  export: lazy(() => import('./views/dataAndintegration/Export')),
  import: lazy(() => import('./views/dataAndintegration/Import')),
  payrollList: lazy(() => import('./views/dataAndintegration/payroll/payrollExportList')),
  payrollExportDetail: lazy(() => import('./views/dataAndintegration/payroll/detail/payrollExportDetail')),
  integrationMapping: lazy(() => import('./views/dataAndintegration/shared/integrationMappingField.screen')),
};

const payrollCodes = {
  home: lazy(() => import('./views/configuration/company/payrollCodes/list')),
  detail: lazy(() => import('./views/configuration/company/payrollCodes/detail/detail')),
  create: lazy(() => import('./views/configuration/company/payrollCodes/detail/create')),
};

const payrollPeriods = {
  home: lazy(() => import('./views/configuration/company/payrollPeriods/list')),
  detail: lazy(() => import('./views/configuration/company/payrollPeriods/detail/detail')),
  create: lazy(() => import('./views/configuration/company/payrollPeriods/detail/create')),
};

const calculationPolicies = {
  home: lazy(() => import('./views/configuration/company/calculationPolicies/list')),
  detail: lazy(() => import('./views/configuration/company/calculationPolicies/detail/detail')),
  create: lazy(() => import('./views/configuration/company/calculationPolicies/detail/create')),
  calculationPolicyDetails: lazy(() => import('./views/configuration/company/calculationPolicies/calculationPolicyDetails/detail')),
};

const schedules = {
  home: lazy(() => import('./views/configuration/company/schedules/list')),
  detail: lazy(() => import('./views/configuration/company/schedules/detail/detail')),
  create: lazy(() => import('./views/configuration/company/schedules/detail/create')),
};

const configuration = {
  home: lazy(() => import('./views/configuration/home/Home')),
  company: lazy(() => import('./views/configuration/company/Company')),
  payrollCodes,
  payrollPeriods,
  calculationPolicies,
  schedules,
};

const testEmployees = {
  list: lazy(() => import('./views/test/employees/list/TestEmployeeList')),
  detail: lazy(() => import('./views/test/employees/detail/TestEmployeeDetails')),
  create: lazy(() => import('./views/test/employees/create/TestEmployeeCreate')),
};

const testPunches = {
  list: lazy(() => import('./views/test/punches/list')),
  detail: lazy(() => import('./views/test/punches/details')),
  import: lazy(() => import('./views/test/punches/import')),
};

const audit = {
  home: lazy(() => import('./views/audit/Home')),
  dataObjects: {
    calculationPolicy: lazy(() => import('./views/audit/dataObjects/auditCalculationPolicies')),
  },
};

const test = {
  home: lazy(() => import('./views/test/home/Home')),
  calculationPolicies: lazy(() => import('./views/test/company/calculationPolicies/list')),
  testEmployees,
  testPunches,
  payroll: lazy(() => import('./views/test/payroll/testPayrollList')),
  testPayrollDetail: lazy(() => import('./views/test/payroll/detail/testPayrollDetail')),
  showIcons: lazy(() => import('./views/test/internal/svgIcons')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems: RouteAndMenuItemModel = {
  mainMenuItems: [
    /*
    {
      path: 'testsse',
      exact: true,
      redirect: true,
      element: SSEtest,
      to: `testsse`,
    },
    */
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      element: dashboard,
      to: `dashboard`,
    },
    {
      path: `dashboard`,
      element: dashboard,
      label: 'menu.dashboard',
      icon: 'dashboard-1',
      order: -999,
    },
    {
      path: `webclock`,
      exact: true,
      redirect: true,
      element: webclock.home,
      to: `webclock/home`,
      label: 'menu.webclock',
      icon: 'clock',
      subs: [
        { path: '/', label: 'menu.webclock', element: webclock.home, hideInMenu: true },
        { path: '/:employeePunchId', label: 'menu.webclock', element: webclock.details, hideInMenu: true },
      ],
      order: 25,
    },
    {
      path: 'timecard',
      element: timecard.home,
      label: 'menu.timecard',
      icon: 'clipboard',
      order: 45,
    },
    /*
    {
      path: 'schedule',
      element: schedule.home,
      label: 'menu.schedule',
      icon: 'calendar',
      order: 45,
    },
    */
    {
      path: `employees`,
      exact: true,
      redirect: true,
      element: employees.list,
      to: `employees/list`,
      label: 'menu.employee.title',
      icon: 'user',
      subs: [
        { path: '/list', label: 'menu.employee.list', element: employees.list, hideInMenu: false },
        { path: '/detail/:employeeId', label: 'menu.employee.detail', element: employees.detail, hideInMenu: true },
        { path: '/employeeTimecard/', label: 'menu.employee.timecard', element: employees.detail, hideInMenu: false },
        { path: '/employeeTimecard/:employeeId', label: 'menu.employee.timecard', element: employees.detail, hideInMenu: true },
      ],
      order: 100,
    },
    {
      path: `dataAndIntegration`,
      exact: true,
      redirect: true,
      element: dataAndIntegration.import,
      to: `dataAndIntegration/import`,
      label: 'menu.dataAndIntegration.title',
      icon: 'sync-horizontal',
      subs: [
        /*
        { path: '/appAndIntegration', label: 'menu.dataAndIntegration.appAndIntegration', element: dataAndIntegration.appAndIntegration },
        { path: '/export', label: 'menu.dataAndIntegration.export', element: dataAndIntegration.export },
        { path: '/import', label: 'menu.dataAndIntegration.import', element: dataAndIntegration.import },
        */
        { path: '/payroll', label: 'menu.dataAndIntegration.payroll', element: dataAndIntegration.payrollList },
        { path: '/payroll/details/:id', hideInMenu: true, label: 'menu.dataAndIntegration.payroll', element: dataAndIntegration.payrollExportDetail },
        { path: '/integrations/:id/integrationMapping/:integrationMappingId', hideInMenu: true, label: 'ABC', element: dataAndIntegration.integrationMapping },
      ],
      order: 450,
    },
    {
      path: 'receipes',
      element: receipes.home,
      // label: 'menu.receipes',
      // icon: 'print',
      order: 350,
      hide: true,
      subs: [
        { path: '/receipes', hideInMenu: true, element: receipes.detail },
        { path: '/receipes/:id', hideInMenu: true, element: receipes.detail },
      ],
    },
    /*
    {
      path: 'reporting',
      element: reporting.home,
      label: 'menu.reporting',
      icon: 'print',
      order: 350,
    },
    */
    {
      path: `test/`,
      element: test.home,
      label: 'menu.test',
      icon: 'science',
      exact: false,
      redirect: true,
      subs: [
        { path: 'calculationPolicies', element: test.calculationPolicies, hideInMenu: true },
        { path: 'employees', element: test.testEmployees.list, hideInMenu: true },
        { path: 'employees/create', element: test.testEmployees.create, hideInMenu: true },
        { path: 'employees/detail/:employeeId', element: test.testEmployees.detail, hideInMenu: true },
        { path: 'punches', element: test.testPunches.list, hideInMenu: true },
        { path: 'punches/import', element: test.testPunches.import, hideInMenu: true },
        { path: 'punches/:employeeId', element: test.testPunches.detail, hideInMenu: true },
        { path: 'payroll', element: test.payroll, hideInMenu: true },
        { path: 'payroll/details/:id', element: test.testPayrollDetail, hideInMenu: true },
        { path: 'testicons', element: test.showIcons, hideInMenu: true },
      ],
      order: 450,
    },
    {
      path: `audit/`,
      element: audit.home,
      label: 'menu.audit',
      icon: 'notebook-1',
      exact: false,
      redirect: true,
      permission: PermissionsENUM.UPDATEPAYROLLPERIOD, // TODO change permissions
      subs: [{ path: 'dataobjects/calculationPolicies', element: audit.dataObjects.calculationPolicy, hideInMenu: true }],
      order: 499,
    },
    {
      path: `configuration/`,
      element: configuration.home,
      label: 'menu.configuration',
      icon: 'flash',
      exact: false,
      redirect: true,
      subs: [
        { path: 'company', element: configuration.company, hideInMenu: false },

        { path: 'payrollCodes', element: configuration.payrollCodes.home, hideInMenu: true },
        { path: 'payrollCodes/create', element: configuration.payrollCodes.create, hideInMenu: true },
        { path: 'payrollCodes/details/:id', element: configuration.payrollCodes.detail, hideInMenu: true },

        { path: 'payrollPeriods', element: configuration.payrollPeriods.home, hideInMenu: true },
        { path: 'payrollPeriods/create', element: configuration.payrollPeriods.create, hideInMenu: true },
        { path: 'payrollPeriods/details/:id', element: configuration.payrollPeriods.detail, hideInMenu: true },

        { path: 'calculationPolicies', element: configuration.calculationPolicies.home, hideInMenu: true },
        { path: 'calculationPolicies/create', element: configuration.calculationPolicies.create, hideInMenu: true },
        { path: 'calculationPolicies/:calculationPolicyId', element: configuration.calculationPolicies.detail, hideInMenu: true },
        { path: 'calculationPolicies/:calculationPolicyId/calculationPolicyDetails/:calculationPolicyDetailId', element: configuration.calculationPolicies.calculationPolicyDetails, hideInMenu: true },

        { path: 'schedules', element: configuration.schedules.home, hideInMenu: true },
        { path: 'schedules/create', element: configuration.schedules.create, hideInMenu: true },
        { path: 'schedules/details/:id', element: configuration.schedules.detail, hideInMenu: true },
      ],
      order: 500,
    },
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
