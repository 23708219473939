import { DEFAULT_PATHS, IS_AUTH_GUARD_ACTIVE } from '../config';
import { RouteModel } from '../models/route/route.model';
import { RouteAndMenuItemModel } from '../models/route/routeAndMenuItem.model';

const userHasRole = (routeRoles: string, userRole: string) => {
  if (!userRole) return false;
  return routeRoles.includes(userRole);
};

const clearRoute = (route: RouteModel) => {
  const item: RouteModel = {};
  item.path = route.path ?? undefined;
  item.to = route.to ?? undefined;
  item.exact = route.exact ?? undefined;
  item.element = route.element ?? undefined;
  item.redirect = route.redirect ?? undefined;

  return item;
};
const clearMenuItem = (menuItem: any) => {
  const item: any = {};
  ['path', 'label', 'icon', 'isExternal', 'subs', 'mega', 'megaParent'].forEach((key) => {
    if (menuItem[key]) item[key] = menuItem[key];
  });
  return item;
};
const clearFlattedMenuItem = (menuItem: any) => {
  const item: any = {};
  ['path', 'label', 'isExternal'].forEach((key) => {
    if (menuItem[key]) item[key] = menuItem[key];
  });
  return item;
};

export const convertToRoutes = ({
  data = [] as any,
  isLogin = false,
  userRole = null,
  authGuardActive = IS_AUTH_GUARD_ACTIVE,
  unauthorizedPath = DEFAULT_PATHS.UNAUTHORIZED,
  loginPath = DEFAULT_PATHS.LOGIN,
  invalidAccessPath = DEFAULT_PATHS.INVALID_ACCESS,
  noLayout = false,
}) => {
  let items: any = [];
  if (Array.isArray(data)) {
    items = data;
  } else {
    items = [...data.sidebarItems, ...data.mainMenuItems];
  }

  const routes: any = [];
  return () => {
    const itemMapper: any = (item: any) => {
      const tempItem = { ...item };
      if (item.hideInRoute) return itemMapper({});

      if (item.subs) tempItem.exact = true;

      /* Authentication Guard */
      if (authGuardActive) {
        if (tempItem.roles) tempItem.protected = true;
        if (tempItem.publicOnly) {
          delete tempItem.roles;
          delete tempItem.protected;
        }
        if (tempItem.protected) {
          if (!isLogin) {
            tempItem.redirect = true;
            tempItem.to = {
              pathname: loginPath,
              state: { from: tempItem.path },
            };
          } else if (tempItem.roles) {
            if (!userHasRole(tempItem.roles, userRole as any)) {
              tempItem.redirect = true;
              tempItem.to = {
                pathname: unauthorizedPath,
                state: { from: tempItem.path },
              };
            }
          }
        } else if (tempItem.publicOnly && isLogin) {
          tempItem.redirect = true;
          tempItem.to = {
            pathname: invalidAccessPath,
            state: { from: tempItem.path },
          };
        }
      }

      if (Object.keys(tempItem).length > 0 && !item.isExternal) {
        if (item.noLayout && noLayout) {
          routes.push(clearRoute({ ...tempItem, exact: true }));
        }
        if (!noLayout && item.noLayout !== true) {
          routes.push(clearRoute(tempItem));
        }
      }

      if (item.subs) {
        return item.subs.map((sub: any) => {
          const controlledSub = { ...sub, path: tempItem.path + sub.path };
          if (authGuardActive) {
            if (tempItem.protected) controlledSub.protected = true;
            if (tempItem.roles) {
              if (!sub.roles) controlledSub.roles = tempItem.roles;
              else {
                // common roles..
                controlledSub.roles = tempItem.roles.filter((x: any) => sub.roles.includes(x));

                if (controlledSub.roles && controlledSub.roles.length === 0) {
                  controlledSub.inaccessible = true;
                  console.log(`This route(${controlledSub.path}) is inaccessible. Please check the roles you defined in the hierarchical structure.`, controlledSub);
                }
              }
            } else if (tempItem.publicOnly) {
              controlledSub.publicOnly = true;
            }
            if (controlledSub.roles && controlledSub.roles.length === 0) delete controlledSub.roles;

            if (!controlledSub.inaccessible) return itemMapper(controlledSub);
            return itemMapper({});
          }
          return itemMapper(controlledSub);
        });
      }
      return tempItem;
    };
    items.map(itemMapper);
    return routes;
  };
};

export const convertToMenuItems = ({ data = {} as RouteAndMenuItemModel, authGuardActive = IS_AUTH_GUARD_ACTIVE, isLogin = false, userRole = null }) => {
  let items: any = [];
  if (Array.isArray(data)) {
    items = data;
  } else {
    items = [...data.sidebarItems, ...data.mainMenuItems];
  }

  items = items.filter((item: RouteModel) => item.hide !== true).sort((a: RouteModel, b: RouteModel) => (a.order ?? 1) - (b.order ?? 1));

  const itemMapper = (item: any) => {
    const tempItem = { ...item };

    if (authGuardActive) {
      /* Authentication Guard */
      if (tempItem.roles) tempItem.protected = true;

      if (tempItem.publicOnly) {
        delete tempItem.roles;
        delete tempItem.protected;
      }

      if (tempItem.protected) {
        if (!isLogin) {
          return {};
        }
        if (tempItem.roles) {
          if (!userHasRole(tempItem.roles, userRole as any)) {
            return {};
          }
        }
      } else if (tempItem.publicOnly && isLogin) {
        return {};
      }
    }

    if (tempItem.subs) {
      tempItem.subs = item.subs
        .map((sub: any) => {
          const controlledSub = { ...sub, path: tempItem.path + sub.path };
          if (tempItem.mega || tempItem.megaParent) controlledSub.megaParent = true;

          if (authGuardActive) {
            if (tempItem.protected) controlledSub.protected = true;

            if (tempItem.roles) {
              if (!sub.roles) controlledSub.roles = tempItem.roles;
              else {
                // common roles..
                controlledSub.roles = tempItem.roles.filter((x: any) => sub.roles.includes(x));

                if (controlledSub.roles && controlledSub.roles.length === 0) {
                  controlledSub.inaccessible = true;
                  console.log(`This menu item(${controlledSub.path}) is inaccessible. Please check the roles you defined in the hierarchical structure.`, controlledSub);
                }
              }
            } else if (tempItem.publicOnly) {
              controlledSub.publicOnly = true;
            }
            if (controlledSub.roles && controlledSub.roles.length === 0) delete controlledSub.roles;

            if (!controlledSub.inaccessible) return itemMapper(controlledSub);
            return itemMapper({});
          }
          return itemMapper(controlledSub);
        })
        .filter((x: any) => Object.keys(x).length > 0);

      if (tempItem.subs.length === 0) delete tempItem.subs;
    }
    if (tempItem.label && !item.hideInMenu) return clearMenuItem(tempItem);
    return {};
  };
  return items.map(itemMapper).filter((x: any) => Object.keys(x).length > 0);
};

export const convertToSearchItems = ({ data = [] as any, authGuardActive = IS_AUTH_GUARD_ACTIVE, isLogin = false, userRole = null }) => {
  let items: any = [];
  if (Array.isArray(data)) {
    items = data;
  } else {
    items = [...data.sidebarItems, ...data.mainMenuItems];
  }

  const menuItems: any = [];

  return () => {
    const itemMapper = (item: any) => {
      let tempItem = { ...item };
      if (tempItem.hideInMenu || tempItem.isExternal || tempItem.hideInRoute) {
        return {};
      }
      if (authGuardActive) {
        /* Authentication Guard */
        if (tempItem.roles) tempItem.protected = true;

        if (tempItem.publicOnly) {
          delete tempItem.roles;
          delete tempItem.protected;
        }

        if (tempItem.protected) {
          if (!isLogin) {
            tempItem = {};
          } else if (tempItem.roles) {
            if (!userHasRole(tempItem.roles, userRole as any)) {
              tempItem = {};
            }
          }
        } else if (tempItem.publicOnly && isLogin) {
          tempItem = {};
        }
      }

      if (Object.keys(tempItem).length > 0 && tempItem.label) menuItems.push(clearFlattedMenuItem(tempItem));

      if (item.subs) {
        return item.subs.map((sub: any) => {
          const controlledSub = { ...sub, path: tempItem.path + sub.path };

          if (authGuardActive) {
            if (tempItem.protected) controlledSub.protected = true;

            if (tempItem.roles) {
              if (!sub.roles) controlledSub.roles = tempItem.roles;
              else {
                // common roles..
                controlledSub.roles = tempItem.roles.filter((x: any) => sub.roles.includes(x));

                if (controlledSub.roles && controlledSub.roles.length === 0) {
                  controlledSub.inaccessible = true;
                  console.log(`This menu item(${controlledSub.path}) is inaccessible. Please check the roles you defined in the hierarchical structure.`, controlledSub);
                }
              }
            } else if (tempItem.publicOnly) {
              controlledSub.publicOnly = true;
            }
            if (controlledSub.roles && controlledSub.roles.length === 0) delete controlledSub.roles;

            if (!controlledSub.inaccessible) return itemMapper(controlledSub);
            return itemMapper({});
          }
          return itemMapper(controlledSub);
        });
      }
      return tempItem;
    };
    items.map(itemMapper);
    return menuItems;
  };
};

export const getRoutes = ({ data = {}, isLogin = false, userRole = null }) =>
  convertToRoutes({
    data,
    isLogin,
    userRole,
    authGuardActive: IS_AUTH_GUARD_ACTIVE,
    unauthorizedPath: DEFAULT_PATHS.UNAUTHORIZED,
    loginPath: DEFAULT_PATHS.LOGIN,
    invalidAccessPath: DEFAULT_PATHS.INVALID_ACCESS,
    noLayout: false,
  })();

export const getLayoutlessRoutes = ({ data = {} as any }) => convertToRoutes({ data, noLayout: true })();
export const getMenuItems = ({ data = [] as any, isLogin = false, userRole = null }) => convertToMenuItems({ data, isLogin, userRole, authGuardActive: IS_AUTH_GUARD_ACTIVE });

export const getSearchItems = ({ data = [] as any, isLogin = false, userRole = null }) => convertToSearchItems({ data, isLogin, userRole, authGuardActive: IS_AUTH_GUARD_ACTIVE });
