import React, { memo, Suspense } from 'react';
// import { Navigate, Switch } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { DEFAULT_PATHS } from '../../config';
import { RouteModelV2 } from '../../models/route/route.model';

const RouteIdentifier = React.memo<{ routes: RouteModelV2[]; fallback: any; notFoundPath?: any }>(({ routes, fallback = <div className="loading" />, notFoundPath = DEFAULT_PATHS.NOTFOUND }) => {
  return (
    <Suspense fallback={fallback}>
      <Routes>
        {routes.map((route, rIndex) => {
          return <Route key={rIndex} path={route.path ?? ''} element={<route.element />} />;
        })}
      </Routes>
    </Suspense>
  );
});

export default memo(RouteIdentifier);
